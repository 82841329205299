// TODO modify client side rendering using the browser API here.
import { GatsbyBrowser } from "gatsby";
import React from "react";
import { PageContextProvider } from "./src/context/PageContext";
import { GatsbyPageContext } from "./src/models/pages/GatsbyPageContext";
import "./src/styles/main.css";

export const wrapPageElement: GatsbyBrowser<
  unknown,
  GatsbyPageContext
>["wrapPageElement"] = ({ element, props }) => (
  <PageContextProvider pageContext={props.pageContext}>{element}</PageContextProvider>
);
