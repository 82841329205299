exports.components = {
  "component---src-templates-sanity-article-list-template-tsx": () => import("./../../../src/templates/SanityArticleListTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-article-list-template-tsx" */),
  "component---src-templates-sanity-article-template-tsx": () => import("./../../../src/templates/SanityArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-article-template-tsx" */),
  "component---src-templates-sanity-blog-article-template-tsx": () => import("./../../../src/templates/SanityBlogArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-blog-article-template-tsx" */),
  "component---src-templates-sanity-contact-template-tsx": () => import("./../../../src/templates/SanityContactTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-contact-template-tsx" */),
  "component---src-templates-sanity-event-list-template-tsx": () => import("./../../../src/templates/SanityEventListTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-event-list-template-tsx" */),
  "component---src-templates-sanity-general-content-template-tsx": () => import("./../../../src/templates/SanityGeneralContentTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-general-content-template-tsx" */),
  "component---src-templates-sanity-learning-hub-article-list-template-tsx": () => import("./../../../src/templates/SanityLearningHubArticleListTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-learning-hub-article-list-template-tsx" */),
  "component---src-templates-sanity-learning-hub-article-template-tsx": () => import("./../../../src/templates/SanityLearningHubArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-learning-hub-article-template-tsx" */),
  "component---src-templates-sanity-news-article-list-template-tsx": () => import("./../../../src/templates/SanityNewsArticleListTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-news-article-list-template-tsx" */),
  "component---src-templates-sanity-news-article-template-tsx": () => import("./../../../src/templates/SanityNewsArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-news-article-template-tsx" */),
  "component---src-templates-sanity-not-found-template-tsx": () => import("./../../../src/templates/SanityNotFoundTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-not-found-template-tsx" */),
  "component---src-templates-sanity-platform-update-article-list-template-tsx": () => import("./../../../src/templates/SanityPlatformUpdateArticleListTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-platform-update-article-list-template-tsx" */),
  "component---src-templates-sanity-platform-update-article-template-tsx": () => import("./../../../src/templates/SanityPlatformUpdateArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-platform-update-article-template-tsx" */),
  "component---src-templates-sanity-product-template-tsx": () => import("./../../../src/templates/SanityProductTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-product-template-tsx" */),
  "component---src-templates-sanity-start-template-tsx": () => import("./../../../src/templates/SanityStartTemplate.tsx" /* webpackChunkName: "component---src-templates-sanity-start-template-tsx" */)
}

