import React, { createContext } from "react";
import { GatsbyPageContext } from "../models/pages/GatsbyPageContext";

const PageContext = createContext<GatsbyPageContext>({} as GatsbyPageContext);

interface PageContextProviderProps {
  children?: React.ReactNode;
  pageContext: GatsbyPageContext;
}

const PageContextProvider: React.FC<PageContextProviderProps> = ({
  pageContext,
  children
}) => (
  <PageContext.Provider
    value={{
      ...pageContext
    }}
  >
    {children}
  </PageContext.Provider>
);

export { PageContext, PageContextProvider };
